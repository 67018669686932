import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="preference"
export default class extends Controller {
  static targets = [ "button", "input" ]

  toggle() {
    const button = this.buttonTarget
    const columnName = button.dataset.columnName
    const isChecked = button.getAttribute('aria-checked') === "true"

    button.setAttribute("aria-checked", !isChecked);

    const buttonAttribute = button.getAttribute('aria-checked')

    this.inputTarget.value = buttonAttribute == "true" ? "1" : "0"
  }
}
