import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="column-preference"
export default class extends Controller {
  static targets = ["form", "button", "input", "hiddenField"]

  updateHiddenField() {
    const columns = {};

    this.inputTargets.forEach(input => {
      columns[input.dataset.columnName] = input.value
    })

    this.hiddenFieldTarget.value = JSON.stringify(columns)
  }
}
