import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";

// Connects to data-controller="help-dashboard"
export default class extends Controller {
  static targets = ["autodrive", "empty"];

  steps = [
    {
      element: "#bank",
      popover: {
        description:
          "Ajouter une banque et renseignez toutes les informations nécessaires.",
        side: "right",
        align: "start",
      },
    },
    {
      element: "#create",
      popover: {
        description:
          "Créer un contact, une facture, un devis ou un article et renseigner toutes les informations nécessaires.",
        side: "right",
        align: "start",
      },
    },
    {
      element: "#sidebar",
      popover: {
        description:
          "Gérer et créer vos factures, devis, contacts et articles.",
        side: "bottom",
        align: "start",
      },
    },
    {
      element: "#accountant",
      popover: {
        description:
          "Extraire les éléments comptables relatifs à votre facturation.",
        side: "right",
        align: "start",
      },
    },
    {
      element: "#settings",
      popover: {
        description:
          "Accéder au paramétrage de votre compte, société, établissements, comptes bancaires, paramètres de facturation et utilisateurs/droits.",
        side: "bottom",
        align: "center",
      },
    },
    {
      element: "#help",
      popover: {
        description:
          "Accéder aux documents d'aides sur Billygen.",
        side: "bottom",
        align: "center",
      },
    },
    {
      element: "#notice",
      popover: {
        description:
          "Consulter vos notifications tel que les dernières mis à jour de Billygen.",
        side: "bottom",
        align: "center",
      },
    },
  ]

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      if (this.hasEmptyTarget && this.emptyTarget.value == "true") {
        this.driveEmpty();
      } else {
        this.drive();
      }
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_dashboard: false,
        },
      }),
    }).then((response) => response.json());
  }

  drive() {
    const driverObj = driver({
      showProgress: false,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        this.stopAutodrive();
        driverObj.destroy();
      },
      steps:
        this.steps
    });

    driverObj.drive();
  }

  driveEmpty() {
    const driverObj = driver({
      showProgress: false,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep()) {
          this.stopAutodrive();
        }
        driverObj.destroy();
      },
      steps: this.steps
    });

    driverObj.drive();
  }
}
