import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";

// Connects to data-controller="help-product"
export default class extends Controller {
  static targets = ["autodrive"];

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      this.drive();
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_product: false,
        },
      }),
    }).then((response) => response.json());
  }

  drive() {
    const driverObj = driver({
      showProgress: false,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        this.stopAutodrive();
        driverObj.destroy();
      },
      steps: [
        {
          element: "#edit",
          popover: {
            description:
              "Vous pouvez modifier les informations de votre article.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#delete",
          popover: {
            description:
              "Vous pouvez supprimer l'article s'il n'est pas utilisé sur des factures numérotées.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#new",
          popover: {
            description: "Vous pouvez créer un nouvel article.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#duplicate",
          popover: {
            description:
              "Vous pouvez dupliquer un article pour pouvoir créer plus rapidement vos articles.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#comment",
          popover: {
            description:
              "Vous avez la possibilité de renseigner un commentaire concernant votre article, en plus de la description en haut. Ce commentaire ne sera pas repris sur la facture.",
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }
}
