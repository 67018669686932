import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";

// Connects to data-controller="help-quotations"
export default class extends Controller {
  static targets = ["autodrive", "empty"];

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      if (this.hasEmptyTarget && this.emptyTarget.value == "true") {
        this.driveEmpty();
      } else {
        this.drive();
      }
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_quotations: false,
        },
      }),
    }).then((response) => response.json());
  }

  drive() {
    const driverObj = driver({
      showProgress: false,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        this.stopAutodrive();
        driverObj.destroy();
      },
      steps: [
        {
          element: "#new",
          popover: {
            description: "Vous pouvez créer un devis.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#archives",
          popover: {
            description: "Visualisez vos dossier d'archives.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#view",
          popover: {
            description: 'Visualisez le devis en cliquant sur "+"',
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }

  driveEmpty() {
    const driverObj = driver({
      showProgress: false,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep()) {
          this.stopAutodrive();
        }
        driverObj.destroy();
      },
      steps: [
        {
          element: "#new",
          popover: {
            description: "Vous pouvez créer un devis.",
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }
}
