import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";

// Connects to data-controller="help-invoice"
export default class extends Controller {
  static targets = ["autodrive"];

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      this.drive();
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_invoice: false,
        },
      }),
    }).then((response) => response.json());
  }

  drive() {
    const driverObj = driver({
      showProgress: false,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        this.stopAutodrive();
        driverObj.destroy();
      },
      steps: [
        {
          element: "#show",
          popover: {
            description:
              "Vous pouvez masquer certains champs en cliquant sur l'oeil.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#pdf",
          popover: {
            description:
              "Vous pouvez télécharger la facture en PDF ou le fichier XML Factur-X.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#edit",
          popover: {
            description:
              "Vous pouvez modifier la facture tant qu'elle n'est pas numérotée.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#duplicate",
          popover: {
            description:
              "Vous pouvez dupliquer la facture, afin de créer rapidement une nouvelle facture .",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#numbered",
          popover: {
            description:
              "Vous pouvez numéroter la facture. Elle ne sera plus modifiable ensuite.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#delete",
          popover: {
            description:
              'VVous pouvez supprimer la facture, seulement si elle est en statut "Brouillon"',
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }
}
