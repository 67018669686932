import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";

// Connects to data-controller="help-new-invoice-product"
export default class extends Controller {
  static targets = ["autodrive"];

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      this.drive();
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_new_quotation_product: false,
        },
      }),
    }).then((response) => response.json());
  }

  // TODO
  drive() {
    const driverObj = driver({
      showProgress: true,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        this.stopAutodrive();
        driverObj.destroy();
      },
      steps: [
        {
          element: "#name",
          popover: {
            description:
              "Renseignez votre article dans la liste déroulante vous proposant les articles déjà créés. Ou renseignez votre article manuellement",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#discount",
          popover: {
            description: 'Vous pouvez ajouter une remise en cliquant sur "+".',
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#charge",
          popover: {
            description:
              'Vous pouvez ajouter des frais et charges en cliquant sur "+".',
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#add",
          popover: {
            description:
              'Ajoutez un article en cliquant sur "Ajouter une ligne".',
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }
}
