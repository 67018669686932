import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";

// Connects to data-controller="help-new-customer-business-entity"
export default class extends Controller {
  static targets = ["autodrive"];

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      this.drive();
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_new_customer_business_entity: false,
        },
      }),
    }).then((response) => response.json());
  }

  drive() {
    const driverObj = driver({
      showProgress: true,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        this.stopAutodrive();
        driverObj.destroy();
      },
      steps: [
        {
          element: "#name",
          popover: {
            description:
              "Saisissez le nom du client. Grâce à l'API Sirène auquel nous sommes connectés, un menu déroulant vous affiche des suggestions en fonction de votre saisie. \nLa sélection d'une suggestion renseignera automatiquement le SIREN, le Numéro de TVA et le code NAF, ainsi que la liste des établissements dans la section \"Choix d'établissements\".",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#business_name",
          popover: {
            description:
              'Si le nom commercial est différent du "Nom" que vous avez saisi précédemment, indiquez cette information dans ce champ.',
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#siren",
          popover: {
            description:
              "Insérez dans ce champ le numéro SIREN . Il se compose de 14 chiffres.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#vat_number",
          popover: {
            description:
              "Veuillez saisir le numéro de TVA de 9 chiffres qui commence par FR, c'est le numéro d'identification individuel attribué à chaque entreprise par l'administration fiscale française (EX:FR XXXXXXXXX).",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#naf_code",
          popover: {
            description:
              "Insérez le code NAF qui se compose de 4 chiffres et 1 lettre (Ex:0000A).",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#website",
          popover: {
            description: "Insérez le lien du site internet du contact.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#logo",
          popover: {
            description:
              "Pour insérer le Logo, cliquez ici et télécharger une image.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#add",
          popover: {
            description:
              'Si l\'établissement n\'est pas ajouté automatiquement dans la section "Choix d\'établissement", cliquez sur "+ Ajoutez un nouvel établissement manuellement", et renseigner les informations complémentaires (SIREN et adresse).',
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#next",
          popover: {
            description:
              'Cliquez ensuite sur "Suivant" pour valider, enregistrer les informations renseignées et passer à l\'étape suivante.',
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }
}
