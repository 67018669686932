import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";

// Connects to data-controller="help-settings"
export default class extends Controller {
  static targets = ["autodrive"];

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      this.drive();
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_settings: false,
        },
      }),
    }).then((response) => response.json());
  }

  drive() {
    const driverObj = driver({
      showProgress: false,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        this.stopAutodrive();
        driverObj.destroy();
      },
      steps: [
        {
          element: "#due_types",
          popover: {
            description:
              "Vous pouvez créer de nouvelles échéances ou supprimer celles qui ne vous seront pas nécessaires.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#mentions",
          popover: {
            description:
              "Vous pouvez créer de nouvelles mentions en renseignant le titre de la mention ainsi que sa description.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#numberings",
          popover: {
            description:
              "Vous pouvez paramétrer la numérotation des factures ou devis.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#tva_rate",
          popover: {
            description:
              "Vous pouvez sélectionner le pays de références pour vos taux de TVA? Vous pouvez en sélectionner plusieurs.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#sales",
          popover: {
            description: "Vous pouvez paramétrer vos conditions de ventes.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#emails",
          popover: {
            description: "Vous pouvez paramétrer vos mails d'envoi.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#categories",
          popover: {
            description: "Vous pouvez paramétrer vos catégories.",
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }
}
