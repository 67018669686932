import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "date", "dueType", "dueDate" ];

  connect() {
    this.calculateDueDate();
  }

  calculateDueDate() {
    if (this.hasDueDateTarget) {
      const regexp = /^([0-9]+)J(FDM)?/
      const match = this.dueTypeTarget.value.match(regexp);

      // If due type and due date are selected
      if (match && match.length == 3 && this.dateTarget.value) {
        let newDueDate = new Date(this.dateTarget.value);

        // Calculate addition of (30|45|60) days
        newDueDate.setDate(newDueDate.getDate() + parseInt(match[1]));

        // Calculate end of month date if needed
        if (match[2] == "FDM") {
          newDueDate = new Date(newDueDate.getFullYear(), newDueDate.getMonth() + 1, 0);
        }

        // Format new due date as YYYY-MM-DD
        let d = new Date(newDueDate);
        let month = (d.getMonth() + 1).toString().padStart(2, '0');
        let day = d.getDate().toString().padStart(2, '0');
        let year = d.getFullYear();
        newDueDate = [year, month, day].join('-');

        this.dueDateTarget.value = newDueDate;
      }
    }
  }
}
